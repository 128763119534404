<template>
  <v-container fill-height>
    <v-row>
      <v-col class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firebase, auth } from '@/components/utils/firebase'
import database from '@/database'
import { getGoogleConnections, getTokens } from '@/services/google'
import {
  createUser,
  getUser,
  updateUser,
  getUserGoogleTokens,
  updateUserGoogleTokens
} from '@/entities/user'
import { createNamespacedHelpers } from 'vuex'
// import { signUpForTracking } from '@/components/utils/eventTracker'
const { mapMutations: mapMutationsAuth, mapActions: mapActionsAuth } =
  createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')

export default {
  data: () => ({}),
  methods: {
    ...mapMutationsAuth(['updateAccessToken']),
    ...teamStore.mapActions(['loadTeamByUid']),
    ...mapActionsAuth(['signOut']),
    async signIntoFirebase(idToken) {
      const authCredential = firebase.auth.GoogleAuthProvider.credential(idToken)
      const userCredential = await auth.signInWithCredential(authCredential)
      return userCredential
    },
    async signInSuccess({ firebaseUser, token }) {
      this.updateAccessToken(token.accessToken)
      let user = firebase.auth().currentUser
      const userDoc = await getUser(firebaseUser.uid)
      if (!userDoc) {
        let userObj = {
          uid: user.uid,
          name: user.displayName ? user.displayName : user.email.split('@')[0],
          profile: 'No Profile',
          iconURL: user.photoURL,
          email: user.email,
          domain: user.email ? user.email.split('@').pop() : null,
          isAnonymous: false,
          lastSignInTime: user.metadata.lastSignInTime,
          createdAt: user.metadata.creationTime,
          signUpAt: new Date(),
          timeZone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Asia/Tokyo'
        }
        if (this.$route.params.invitedEmail) {
          userObj.invitedEmail = this.$route.params.invitedEmail
        }
        await createUser(user.uid, userObj)

        let tokens = {
          createdAt: new Date(),
          createdBy: user.uid,
          accessToken: token.accessToken,
          idToken: token.idToken
        }
        if (token.refreshToken !== undefined) {
          tokens.refreshToken = token.refreshToken
        }
        await updateUserGoogleTokens(user.uid, tokens)

        // eslint-disable-next-line
        this.$root.$emit('sendTrackingEventByClick', { 
          message: 'click_sign_up_btn',
          option: {}
        })
      } else {
        let tokens = {
          createdAt: new Date(),
          createdBy: user.uid,
          accessToken: token.accessToken,
          idToken: token.idToken
        }
        if (token.refreshToken !== undefined) {
          tokens.refreshToken = token.refreshToken
        }
        await Promise.all([
          updateUserGoogleTokens(user.uid, tokens),
          updateUser(user.uid, {
            name: user.displayName || userDoc.name,
            iconURL: user.photoURL || userDoc.iconURL,
            email: user.email || userDoc.email,
            domain: user.email ? user.email.split('@').pop() : null
          })
        ])

        this.$root.$emit('sendTrackingEventByClick', {
          message: 'click_sign_in_btn',
          option: {}
        })
      }

      // 再現できないエラーがあるので念のため例外処理を設定
      try {
        // アドレスを更新
        const { data: connections } = await getGoogleConnections(token.accessToken)
        if (connections) {
          const addressInFirestore = (this.memberInFirestoreContacts = await database
            .userCollection()
            .getAllContacts(firebaseUser.uid))
          const emailsInFirestore = addressInFirestore.map((e) => e.email)
          const adressInGoogleContact = connections.map((e) => {
            const email =
              e.emailAddresses && e.emailAddresses.length > 0
                ? e.emailAddresses[0].value
                : undefined
            const name =
              e.names && e.names.length > 0 && e.names[0].displayName
                ? e.names[0].displayName
                : email
            return { email, name, createdAt: new Date(), createdBy: firebaseUser.uid }
          })
          const newContacts = adressInGoogleContact
            .filter((e) => {
              return !emailsInFirestore.includes(e.email)
            })
            .filter((e) => {
              return e.email && e.name
            })
          if (newContacts[0]) {
            for (let i = 0; i < newContacts.length; i++) {
              database.userCollection().addMemberToContact(firebaseUser.uid, newContacts[i])
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    isValidState(state) {
      return state === state // TODO:
    },
    async processCode({ clientId, clientSecret, code, redirectURI }) {
      const {
        data: {
          error,
          error_description: errorDescription,
          access_token: accessToken,
          refresh_token: refreshToken,
          id_token: idToken
        }
      } = await getTokens({
        clientId,
        clientSecret,
        code,
        redirectURI
      })

      if (error === undefined) {
        const userCredential = await this.signIntoFirebase(idToken)
        const { user } = userCredential
        return { user, tokens: { accessToken, refreshToken, idToken } }
      } else {
        throw `${error}:${errorDescription}`
      }
    },
    async isValidRefreshToken(refreshToken, id) {
      if (refreshToken === undefined) {
        try {
          const currentTokens = await getUserGoogleTokens(id)
          if (currentTokens.refreshToken === undefined) {
            return false
          }
        } catch (err) {
          return false
        }
      }
      return true
    }
  },
  async mounted() {
    const query = this.$route.query
    const { error, code, state } = query
    if (error || !this.isValidState(state)) {
      alert('サインインできませんでした。')
      this.signOut()
      this.$router.replace('/')
    } else {
      try {
        const redirectURI = state.includes('team-plan')
          ? `${process.env.VUE_APP_DOMAIN}/auth-callback/team-plan`
          : `${process.env.VUE_APP_DOMAIN}/auth-callback`
        const { user, tokens } = await this.processCode({
          clientId: process.env.VUE_APP_GOOGLE_CONFIG_CLIENT_ID,
          clientSecret: process.env.VUE_APP_GOOGLE_CONFIG_SECRET,
          code,
          redirectURI
        })
        if (await this.isValidRefreshToken(tokens.refreshToken, user.uid)) {
          await this.signInSuccess({ firebaseUser: user, token: tokens })
          if (this.$route.params.planType) {
            this.$router.replace(`/register-credit-card/${this.$route.params.planType}`)
          } else {
            const state = this.$route.query.state
            let path = state ? state : '/calendar'
            this.$router.replace(path)
          }

          setTimeout(() => {
            this.loadTeamByUid(user.uid)
          }, 2000)
        } else {
          throw 'Meet Awesomeに再度サインインしてください。'
        }
      } catch (error) {
        alert('サインイン処理中にエラーが起きました。' + error)
        this.signOut()
        this.$router.replace('/sign-in?withConsent=true')
      }
    }
  }
}
</script>
